<template>
	<section class="content-main">
		<!--基本信息-->
		<el-form :model="form" label-width="160px"  ref="form">
			<el-form-item label="店铺信息:">
				<div>账号：{{userName}} ，名称：{{storeInfo.name}}</div>
			</el-form-item>
			<!-- <el-form-item label="用户id:">
				{{form.uid}}
			</el-form-item> -->
			<el-form-item label="支付宝账号:">
				<el-input v-model.trim="form.account" class="short-width" clearable></el-input>
			</el-form-item>
			<el-form-item label="商户姓名:">
				<el-input v-model.trim="form.contact_name" class="short-width" clearable></el-input>
			</el-form-item>
			<el-form-item label="商户手机号:">
				<el-input v-model.trim="form.contact_mobile" class="short-width" clearable></el-input>
			</el-form-item>
			<el-form-item label="商户名称:">
				<el-input v-model.trim="form.shop_name" class="short-width" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item label="营业执照是否长期有效:">
				<el-select class="short-width" v-model="long_term">
					<el-option label="是" value="1">是</el-option>
					<el-option label="否" value="0">否</el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item label="营业执照有效期:">
				<el-input v-model.trim="form.date_limitation" class="short-width" placeholder="格式：2020-01-20" clearable></el-input>
			</el-form-item>
			<el-form-item label="营业执照号码:">
				<el-input v-model.trim="form.business_license_no" class="short-width" clearable></el-input>
			</el-form-item>
			<el-form-item label="法人手机号:">
				<el-input type="number" v-model.trim="form.business_license_mobile" class="short-width" clearable></el-input>
			</el-form-item>
			<el-form-item label="所在地区:">
				<el-select v-model="form.province_code" placeholder="请选择省份" clearable>
					<el-option v-for="i in provinceList" :key="i.code" :label="i.name" :value="i.code">
					</el-option>
				</el-select>
				<el-select v-if="cityList.length > 0" v-model="form.city_code" placeholder="请选择县市" clearable>
					<el-option v-for="i in cityList" :key="i.code" :label="i.name" :value="i.code">
					</el-option>
				</el-select>
				<el-select v-if="districtList.length > 0" v-model="form.district_code" placeholder="请选择地区" clearable>
					<el-option v-for="i in districtList" :key="i.code" :label="i.name" :value="i.code">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="详细地址:">
				<el-input v-model.trim="form.detail_address" clearable></el-input>
			</el-form-item>
			<el-form-item label="上传证件照:">
				<el-upload class="avatar-uploader" :action="uploadurl" :show-file-list="false" :data="dataToken" :before-upload="beforeAvatarUpload" :on-success="uploaderSuccess1">
					<img v-if="special_license_pic" :src="special_license_pic" class="avatar">
					<i v-else class="avatar-uploader-icon">代销证图片</i>
				</el-upload>
				<el-upload class="avatar-uploader" :action="uploadurl" :show-file-list="false" :data="dataToken" :before-upload="beforeAvatarUpload" :on-success="uploaderSuccess4">
					<img v-if="business_license_pic" :src="business_license_pic" class="avatar">
					<i v-else class="avatar-uploader-icon">营业执照图片</i>
				</el-upload>
				<el-upload class="avatar-uploader" :action="uploadurl" :show-file-list="false" :data="dataToken" :before-upload="beforeAvatarUpload" :on-success="uploaderSuccess2">
					<img v-if="shop_scene_pic" :src="shop_scene_pic" class="avatar">
					<i v-else class="avatar-uploader-icon">店铺内景照片</i>
				</el-upload>
				<el-upload class="avatar-uploader" :action="uploadurl" :show-file-list="false" :data="dataToken" :before-upload="beforeAvatarUpload" :on-success="uploaderSuccess3">
					<img v-if="shop_sign_board_pic" :src="shop_sign_board_pic" class="avatar">
					<i v-else class="avatar-uploader-icon">店铺门头照图片</i>
				</el-upload>
			</el-form-item>
			<el-form-item label="上传身份证:">
				<el-upload class="avatar-uploader" :action="uploadurl" :show-file-list="false" :data="dataToken" :before-upload="beforeAvatarUpload" :on-success="uploaderSuccess5">
					<img v-if="id_card_copy" :src="id_card_copy" class="avatar">
					<i v-else class="avatar-uploader-icon">身份证正面</i>
				</el-upload>
				<el-upload class="avatar-uploader" :action="uploadurl" :show-file-list="false" :data="dataToken" :before-upload="beforeAvatarUpload" :on-success="uploaderSuccess6">
					<img v-if="id_card_national" :src="id_card_national" class="avatar">
					<i v-else class="avatar-uploader-icon">身份证反面</i>
				</el-upload>
			</el-form-item>
			<el-form-item label=" ">
				<el-button type="primary" @click.native="addSubmit" :loading="Loading" class="mr10">提交进件</el-button>
			</el-form-item>
		</el-form>
	</section>
</template>

<script>
	import { storeData, dataPost, audit } from '../../api/api';
	import axios from 'axios';
	export default {
		data() {
			return {
				//创建新增
				Loading: false,
				//新增界面数据
				userName: this.$route.query.username,
				storeInfo: {},
				form: {
					channel: '收啊收',
					store_id: this.$route.query.storeId,
					uid: 10257,
					mcc_code: 'A0004_B0069',
					industry_qualification_type	: 301,
					account: '',
					contact_name: '',
					contact_mobile: '',
					shop_name: '',
					special_license_pic: '',
					shop_scene_pic: '',
					shop_sign_board_pic: '',
					business_license_pic: '',
					province_code: '',
					city_code: '',
					district_code: '',
					detail_address: '',
					long_term: 0,
					date_limitation: '',
					business_license_no: '',
					business_license_mobile: '',
					id_card_copy:'',
					id_card_national:'',
				},
				special_license_pic: '',
				shop_scene_pic: '',
				shop_sign_board_pic: '',
				business_license_pic: '',
				id_card_copy:'',
				id_card_national:'',
				uploadurl: 'https://www.shouyashou.com/merchant/upload', //上传地址 
				url1: "https://www.shouyashou.com/",
				dataToken: {},//上传文件需要携带的参数
				imgUrlHead: '',
				provinceList: [],
				cityList: [],
				districtList: []
			}
		},
		created(){
			// 获取店主信息
			this.getList()
			// 获取地区
			this.getProvinceList(1, 1)
		},
		watch:{
			'form.province_code':function(newVal,oldVal){
				if(newVal != oldVal){
					this.getProvinceList(2, this.form.province_code)
					this.districtList = []
				}
			},
			'form.city_code':function(newVal,oldVal){
				if(newVal != oldVal){
					this.getProvinceList(3, this.form.city_code)
				}
			}
		},
		methods: {
			//获取用户列表
			getList() {
				let para = {
					username: this.userName
				};
				this.listLoading = true;
				storeData(para).then((res) => {
					if(res.code == 1){
						this.storeInfo = res.data.data[0];
						this.form.contact_name = this.storeInfo.contact
						this.form.contact_mobile = this.storeInfo.mobile
						this.form.business_license_mobile = this.storeInfo.mobile
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			beforeAvatarUpload(file){
				this.dataToken.image = file
				this.$loading({
					lock: true,
					text: '上传图片，请稍后',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.3)'
				})
			},
			uploaderSuccess1(res){
				if(res.status == -1){
					this.$message.error(res.message)
				}else{
					this.special_license_pic = this.url1 + res.url;
					this.form.special_license_pic = res.url;
				}
				this.$loading().close()
			},
			uploaderSuccess2(res){
				if(res.status == -1){
					this.$message.error(res.message)
				}else{
					this.shop_scene_pic = this.url1 + res.url;
					this.form.shop_scene_pic = res.url;
				}
				this.$loading().close()
			},
			uploaderSuccess3(res){
				if(res.status == -1){
					this.$message.error(res.message)
				}else{
					this.shop_sign_board_pic = this.url1 + res.url;
					this.form.shop_sign_board_pic = res.url;
				}				
				this.$loading().close()
			},
			uploaderSuccess4(res){
				if(res.status == -1){
					this.$message.error(res.message)
				}else{
					this.business_license_pic = this.url1 + res.url;
					this.form.business_license_pic = res.url;
				}				
				this.$loading().close()
			},
			uploaderSuccess5(res){
				if(res.status == -1){
					this.$message.error(res.message)
				}else{
					this.id_card_copy = this.url1 + res.url;
					this.form.id_card_copy = res.url;
				}				
				this.$loading().close()
			},
			uploaderSuccess6(res){
				if(res.status == -1){
					this.$message.error(res.message)
				}else{
					this.id_card_national = this.url1 + res.url;
					this.form.id_card_national = res.url;
				}				
				this.$loading().close()
			},
			//获取省份
			getProvinceList(type,code){
				let url = this.url1 + 'merchant/getCity?pcode=' + code
				axios({
					method: 'get',
					url: url,
					timeout: 20000,
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
				}).then((res) => {
					if(type == 1) this.provinceList = res.data.data
					if(type == 2) this.cityList = res.data.data
					if(type == 3) this.districtList = res.data.data
				});
			},
			//提交
			addSubmit: function () {
				if(!this.form.account) return this.$message.error('支付宝账号不能为空！')
				if(!this.form.contact_name) return this.$message.error('商户姓名不能为空！')
				if(!this.form.contact_mobile) return this.$message.error('商户手机号不能为空！')
				if(!this.form.shop_name) return this.$message.error('商户名称不能为空！')
				if(!this.form.date_limitation ) return this.$message.error('营业执照有效期不能给为空')
				if(this.form.date_limitation.length != 10) return this.$message.error('营业执照有效期格式不正确！')
				if(!this.form.business_license_no) return this.$message.error('营业执照号码不能为空！')
				if(!this.form.business_license_mobile) return this.$message.error('法人手机号不能为空！')
				if(!this.form.province_code) return this.$message.error('省份编码不能为空！')
				if(!this.form.city_code) return this.$message.error('城市编码不能为空！')
				if(!this.form.district_code) return this.$message.error('地区编码不能为空！')
				if(!this.form.detail_address) return this.$message.error('详细地址不能为空！')
				if(!this.form.special_license_pic) return this.$message.error('代销证图片不能为空！')
				if(!this.form.shop_scene_pic) return this.$message.error('店铺内景照片不能为空！')
				if(!this.form.shop_sign_board_pic) return this.$message.error('店铺门头照图片不能为空！')
				if(!this.form.business_license_pic) return this.$message.error('营业执照照片不能为空！')
				if(!this.form.id_card_copy) return this.$message.error('身份证正面不能为空！')
				if(!this.form.id_card_national) return this.$message.error('身份证反面不能为空！')
				this.Loading = true
				// console.log('***', this.form)
				dataPost(this.form).then((res) => {
					this.Loading = false;
					if(res.code==1){
						const parms1 = {
							mer_id: res.data.mer_id,
							channel: '收啊收',
						}
						audit(parms1).then(res1 => {
							res1.code == 1 && this.$router.push({ name: '进件管理' })
						})
					}else {
						this.$message.error(res.msg);
					}
				});
			},
		}
	}

</script>
<style lang="scss" scoped>
	.avatar-uploader {
		width: 150px;
		height: 150px;
		display: flex;
		align-items: center;
		margin-right: 15px;
		float: left;		
		border-radius: 6px;
		overflow: hidden;
	}

	.avatar-uploader .el-upload {
		display: block;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		border: none
	}
	.avatar-uploader .el-upload img{
		width: 100%
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 16px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: inherit;
		text-align: center;
		border-radius: 6px;
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
	}
	.avatar {
		width: 100%;
		display: block;
	}
	p{ padding: 8px 0 }
</style>